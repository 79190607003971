@font-face {
  font-family: sourceHanSansCN-light;
  src: url(/SourceHanSansCN-Light.otf);
}


* {
  margin: 0;
  padding: 0;
  border: none;
  outline-style: none;
  outline-width: medium;
}

html {
  margin-left: calc(100vw - 100%);
}

body {
  font-family: "sourceHanSansCN-light",Helvetica,Arial,sans-serif;
  background: url(/images/common/bg_image.png) repeat-x fixed;
  line-height: 1.42857143;
  text-align: center;
}

ul {
  list-style: none;
  margin-top: 0;
  margin-bottom: 10px;

  margin: 0;
}

a {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.page-wrapper {
  width: 100%;
}

@media screen and (min-width: 600px) {
  .page-wrapper {
    margin: 0 auto;

    min-height: 441px;
    max-width: 1170px;

    width: 100%;
  }
}


/* Overridding carousel's style */
.carousel .slide {
  background: none;
}

.product-animation-enter {
  opacity: 0.01
}

.product-animation-enter.product-animation-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.product-animation-leave {
  opacity: 1;
}

.product-animation-leave.product-animation-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* Bootstrap modal */
.confirm-modal-dialog {
  margin-top: 30vh;
}

.message-link {
  color: #007bff;
}